<template>
    <TemplateOrderList :data_orderForList="data_orderForList" :data_orderList="data_orderList" />
</template>

<script>
    import TemplateOrderList from '../Template/OrderList';
    import http from '../../js/axios.js';
    export default {
        name: "OrderList",
        components: {TemplateOrderList},
        data() {
            return {
                data_orderForList: "",
                data_orderList: "",
            };
        },
        methods: {},
        created() {
            let token = localStorage.getItem("token");

            //買入紀錄
            http.get('api/pet/orderList', {token: token}, response => {
                    console.log(response);
                let data = response.data;
                if (data.code === 1) {
                    console.log(data.data);
                    this.data_orderList = data.data;
                }
            });
        }
    }
    ;
</script>

<style>
</style>
