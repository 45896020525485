import {createRouter, createWebHistory} from 'vue-router'
//登入
import Login from '@/components/Login'
//首頁
import Index from '@/components/Index'
//買入
import orderForReserve from '@/components/Payfor/OrderForReserve'
//賣出
import OrderReserve from '@/components/Pay/OrderReserve'
//個人信息
import UserInfo from '@/components/My/UserInfo'
//資產信息
import AssetInfo from '@/components/My/AssetInfo'
//買入紀錄
import OrderForList from '@/components/My/OrderForList'
//賣出紀錄
import OrderList from '@/components/My/OrderList'
//買入紀錄-收款人信息
import OrderForPayee from '@/components/My/OrderForPayee'
//資產明細
import MoneyLog from '@/components/My/MoneyLog'
//我的團隊
import Team from '@/components/My/Team'
//設置
import Set from '@/components/My/Set'
//語言
import Lang from '@/components/My/Lang'

const routes = [
    {
        path: '',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/index',
        name: 'index',
        redirect: '/orderForReserve',
        component: Index,
        children: [
            //買入
            {
                path: '/orderForReserve',
                name: 'OrderForReserve',
                component: orderForReserve
            },
            //賣出
            {
                path: '/orderReserve',
                name: 'OrderReserve',
                component: OrderReserve
            },
            //個人信息
            {
                path: '/user',
                name: 'User',
                redirect: '/userInfo',
            },
            //個人信息
            {
                path: '/userInfo',
                name: 'UserInfo',
                component: UserInfo
            },
            //資產信息
            {
                path: '/assetInfo',
                name: 'AssetInfo',
                component: AssetInfo
            },
            //買入紀錄
            {
                path: '/orderForList',
                name: 'OrderForList',
                component: OrderForList
            },
            //賣出紀錄
            {
                path: '/orderList',
                name: 'OrderList',
                component: OrderList
            },
            //買入紀錄-收款人信息
            {
                path: '/orderForPayee',
                name: 'OrderForPayee',
                component: OrderForPayee
            },
            //資產明細
            {
                path: '/moneyLog',
                name: 'MoneyLog',
                component: MoneyLog
            },
            //我的團隊
            {
                path: '/team',
                name: 'Team',
                component: Team
            },
            //設置
            {
                path: '/set',
                name: 'Set',
                component: Set
            },
            //語言
            {
                path: '/lang',
                name: 'Lang',
                component: Lang
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
