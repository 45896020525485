<template>
  
  <Header :page_name="page_name" />
  <div class="home m-auto">
    <div id="loadingIcon" class="d-none justify-content-center  mb-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- <a class="dropdown-item text-golden btn" @click="setLocale('zh')">中文</a>
    <a class="dropdown-item text-golden btn" @click="setLocale('en')">English</a> -->
    <article class="mx-auto">
      <router-view
        v-if="isRouterAlive"
        :GlobalData="GlobalData"
        :page_name="page_name"
      ></router-view>
    </article>
  </div>
  <Nav />
</template>

<script>
import Header from "./Template/Header";
import Nav from "./Template/Nav";

export default {
  name: "Index",
  inject: ["setLocale"],
  components: { Header, Nav },
  props: ["page_name"],
  data() {
    return {
      GlobalData: {
        //左边 menu 缩展
        boxIsActive: false,
      },
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      // alert("reload occure")
      console.log("reload occure");
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    setActiveLanguage(lang) {
      localStorage.setItem("language", lang);
    },
    setLang(evt) {
      // console.log(evt)
      const lang = evt.target.dataset.lang;
      this.setActiveLanguage(lang);
      return history.go(0);
    },
  },
  mounted() {
    let reload = this.reload;
    let isLoading = this.isLoading;

    document.addEventListener("touchstart", (e) => swipeStart(e), false);
    document.addEventListener("touchmove", (e) => swipe(e), false);
    document.addEventListener("touchend", (e) => swipeEnd(e), false);
    const pStart = { x: 0, y: 0 };
    const pCurrent = { x: 0, y: 0 };
    const main = document.querySelector(".home");
    // const main = document.querySelector('body > div');
    let currentMainTransformY = 0;

    function swipeStart(e) {
      if (e.targetTouches) {
        let touch = e.targetTouches[0];
        pStart.x = touch.screenX;
        pStart.y = touch.screenY;
      } else {
        pStart.x = e.screenX;
        pStart.y = e.screenY;
      }

      const getCurrentMainTransformY = () => {
        if (main.style.transform) {
          const regex = /translateY\((.*)px\)/;
          currentMainTransformY = main.style.transform;
          const match = currentMainTransformY.match(regex);
          return parseInt(match[1]);
        } else return 0;
      };
      currentMainTransformY = getCurrentMainTransformY();
      main.style.transition = "10ms ease-in-out";
    }
    function swipe(e) {
      if (e.targetTouches) {
        let touch = e.targetTouches[0];
        pCurrent.x = touch.screenX;
        pCurrent.y = touch.screenY;
      } else {
        pCurrent.x = e.screenX;
        pCurrent.y = e.screenY;
      }
      let changeY = pCurrent.y - pStart.y;
      let totalMainTransformY = currentMainTransformY + changeY;
      let loadingIcon = document.querySelector("#loadingIcon");
      // if (document.body.scrollTop === 0) {
      if (window.scrollY <= 0) {
        // if (totalMainTransformY > 100) {}
        if (totalMainTransformY >= 60) {
          totalMainTransformY = 60;
          isLoading = true;
          loadingIcon.classList.remove("d-none");
          loadingIcon.classList.add("d-flex");
        }
        main.style.transform = `translateY(${totalMainTransformY}px)`;
      }
    }
    function swipeEnd(e) {
      if (isLoading) {
        loading();
      }
    }

    function loading() {
      main.style.transform = `translateY(60px)`;
      /* const node = document.createElement("div");
        const textnode = document.createTextNode("Reload ....");
        node.appendChild(textnode);
        main.appendChild(node); */

      setTimeout(() => {
        reload();
        loadingIcon.classList.add("d-none");
        loadingIcon.classList.remove("d-flex");
        main.style.transition = "1s 10ms ease-in-out";
        main.style.transform = ``;
        isLoading = false;
      }, 500);
    }
  },
};
</script>

<style lang="scss">

header,
article,
nav {
  max-width: 800px;
}
article {
  min-height: calc(100vh - 116px);
}
/* .home {
  max-width: 800px;
  background: url(~@/assets/images/bg.jpg) no-repeat center;
  padding-top: 65px;
  padding-bottom: 65px;
  background-attachment:fixed;
} */
.home {
  max-width: 800px;
  padding-top: 65px;
  padding-bottom: 65px;
}

// .home:before {
//   content: "";
//   display: block;
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   z-index: -10;

//   background: url(~@/assets/images/bg.jpg) no-repeat center;
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   -o-background-size: cover;
//   background-size: cover;
// }

.title_css {
  background-size: contain;
  text-align: center;
  font-size: 26px;
  color: rgb(255, 194, 52);
  font-weight: 600;
  text-shadow: 1px 1px 3px #000;
}
</style>
