<template>
  <div class="container">
    <h2 class="d-block p-3 title_css">{{ title }} {{ $t('article.reservation') }}</h2>

    <div v-if="!sticky.data" class="d-flex justify-content-center mt-5">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div class="appt-info shadow mb-2">
      <ul
        v-for="(item, index) in sticky.data"
        :key="sticky.data"
        class="list-group list-group-flush"
      >
        <li class="list-group-item list-group-item-action">
          {{$t('article.budget')}}：{{ item.money_min }} - {{ item.money_max }}
        </li>
        <li class="list-group-item list-group-item-action">
          {{$t('article.reservation')}}：{{ item.amount }}
        </li>
        <li class="list-group-item list-group-item-action">
          <div v-if="type == 'payfor'">
          {{$t('article.bought')}}：{{ item.amount_y }}
          </div>
          <div v-else-if="type == 'pay'">
          {{$t('article.sale')}}：{{ item.amount_y }}
          </div>
        </li>
        <li class="list-group-item list-group-item-action">
          {{$t('article.reservationFrequency')}}：{{ item.times }}
        </li>
        <li class="list-group-item list-group-item-action">
          <div v-if="type == 'payfor'">
          {{$t('article.boughtFrequency')}}：{{ item.times_y }}
          </div>
          <div v-else-if="type == 'pay'">
          {{$t('article.saleFrequency')}}：{{ item.times_y }}
          </div>
        </li>
        <li class="list-group-item list-group-item-action">
          {{$t('article.status')}}：
          <span v-if="item.status == 'normal'">{{$t('article.statusNormal')}}</span>
          <span v-else-if="item.status == 'pause'">{{$t('article.statusPause')}}</span>
          <span v-else-if="item.status == 'abnormal '">{{$t('article.statusAbnormal')}}</span>
          <span v-else>{{$t('article.statusNoFound')}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div id="pets" class="container">
    <div class="d-flex flex-wrap">
      <div
        v-for="(item, index) in cardsList.data"
        :key="cardsList.data"
        class="col-md-6 col-12"
      >
        <div class="card shadow p-1 m-1">
          <div class="overflow-hidden" style="height: 12rem">
            <img
              :src="aliyuncs_url + item.pet.image"
              class="card-img-top"
              alt=""
            />
          </div>
          <div class="card-body">
            <table class="table table-borderless table-sm">
              <tr>
                <th scope="row">{{$t('article.pet_name')}}：</th>
                <td>{{ item.pet.name }}</td>
              </tr>
              <tr>
                <th scope="row">{{$t('article.trade_no')}}：</th>
                <td>{{ item.trade_no }}</td>
              </tr>
              <tr>
                <th scope="row">{{$t('article.amount')}}：</th>
                <td>{{ item.amount }}</td>
              </tr>
              <tr>
                <th scope="row">{{$t('article.createtime')}}：</th>
                <td>{{ item.createtime }}</td>
              </tr>
              <tr>
                <th scope="row">{{$t('article.user_in')}}：</th>
                <td>{{ item.user_in }}</td>
              </tr>
            </table>

            <div class="text-center justify-content-center">
              <!-- 买入记录 -->
              <div
                v-if="item.status == -1 && type == 'payfor'"
                class="badge bg-danger"
              >
                <h3 class="m-0">{{$t('article.payfor_statusNeg1')}}</h3>
              </div>
              <div
                v-else-if="item.status == 0 && type == 'payfor'"
                class="badge bg-success"
              >
                <h3 class="m-0">{{$t('article.payfor_status0')}}</h3>
              </div>
              <div
                v-else-if="item.status == 1 && type == 'payfor'"
                class="badge bg-dark"
              >
                <h3 class="m-0">{{$t('article.payfor_status1')}}</h3>
              </div>
              <!-- 卖出记录 -->
              <div
                v-else-if="item.status == -1 && type == 'pay'"
                class="badge bg-danger"
              >
                <h3 class="m-0">{{$t('article.pay_statusNeg1')}}</h3>
              </div>
              <div
                v-else-if="item.status == 0 && type == 'pay'"
                class="badge bg-success"
              >
                <h3 class="m-0">{{$t('article.pay_status0')}}</h3>
              </div>
              <div
                v-else-if="item.status == 1 && type == 'pay'"
                class="badge bg-dark"
              >
                <h3 class="m-0">{{$t('article.pay_status1')}}</h3>
              </div>
              <div>
                <router-link v-if="item.status == 0 && type == 'payfor'" :to="'/OrderForPayee?trade_no='+item.trade_no" class="badge ex-btn" :class="[item.script_active == 1 ? 'bg-dark' : 'bg-warning']">
                  <h3 class="m-0">{{$t('OrderListTable.payforPayee')}}</h3>
                </router-link>
                <label :id="'label_trade_'+item.trade_no" v-if="item.status == 0 && type == 'payfor'" class="badge ex-btn" :class="[item.voucher_img != ''? 'bg-dark' : 'bg-warning']">
                  <h3 :id="'text_trade_'+item.trade_no" v-if="item.voucher_img == ''" class="m-0">{{$t('OrderListTable.payforProof')}}</h3>
                  <h3 :id="'text_trade_'+item.trade_no" v-if="item.voucher_img != ''" class="m-0">{{$t('OrderListTable.payforProofuped')}}</h3>
                  <input style="display:none;" type="file" ref="file" :data-tradeno="item.trade_no" @change="fileChange">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import http from "@/js/axios.js";
import axios from 'axios';
import { Toast } from 'bootstrap'
export default {
  components: {},
  props: ['cardsList', 'sticky', 'type', 'GlobalData', 'page_name'],
  data() {
    return {
      aliyuncs_url: localStorage.getItem("aliyuncs_url"),
      stickyStatus: "",
      title: "",
    };
  },
  created() {
    this.changeTitle(this.page_name);
    router.afterEach((to, from, next) => {
      this.changeTitle(to.name);
    });
  },
  methods: {
    changeTitle(name) {
      switch (name) {
        case "OrderForReserve":
          this.title = this.$t('nav.payfor');
          break;
        case "OrderReserve":
          this.title = this.$t('nav.pay');
          break;
        default:
            this.title = this.$t('nav.my');
        break;
      }
    },
    fileChange(event) {
        let token = localStorage.getItem("token");
        let formdata =  new FormData();
        let tradeno = event.target.getAttribute('data-tradeno');
        formdata.append('token', token);
        formdata.append('file', event.target.files[0]);
        http.upload("api/common/uploadToAlioss", formdata, (response) => {
            console.log(tradeno);
            console.log(response);
            console.log(response.data.data.url);
            let voucher_img = response.data.data.url;
            http.post("api/pet/updatePayforProof", {token: token,tradeno:tradeno,voucher_img:voucher_img}, (response) => {
              console.log(response);
              let labelel = document.getElementById("label_trade_"+tradeno);
              let textel = document.getElementById("text_trade_"+tradeno);
              labelel.className = 'badge ex-btn bg-dark';
              textel.textContent = this.$t('OrderListTable.payforProofuped');
              let t = new Toast(document.getElementById("isupload"));
              t.show()
            });
        });
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.card {
  background: rgba(255, 255, 255, 0.9);
}
.ex-btn{
  margin: 10px 5px;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}
@media screen and (max-width: 380px) {
  h3{
    font-size: 18px;
  }
}
</style>
