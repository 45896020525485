<template>
    <nav id="tabbar" class="m-auto fixed-bottom">
        <ul class="nav justify-content-around">
            <li class="nav-item">
                <div v-if="orderfor_notify" class="o_notify" >{{orderfor_num}}</div>
                <!-- <router-link @click="doHideNotify('orderfor')" to="/OrderForReserve" :class="['nav-link', 'fw-bold', 'py-3']" active-class="active">{{$t('nav.payfor')}}</router-link> -->
                <a @click="doHideNotify('orderfor')" :class="['nav-link', 'fw-bold', 'py-3',{active:isActive.payfor}]">{{$t('nav.payfor')}}</a>
            </li>
            <li class="nav-item">
                <div v-if="order_notify" class="o_notify">{{order_num}}</div>
                <!-- <router-link @click="doHideNotify('order')" to="/OrderReserve" class="nav-link fw-bold py-3" active-class="active">{{$t('nav.pay')}}</router-link> -->
                <a @click="doHideNotify('order')" :class="['nav-link','fw-bold','py-3',{active:isActive.pay}]">{{$t('nav.pay')}}</a>
            </li>
            <li class="nav-item">
                <!-- <router-link to="/UserInfo" class="nav-link fw-bold py-3" active-class="active">{{$t('nav.my')}}</router-link> -->
                <a @click="doHideNotify('my')"  :class="['nav-link','fw-bold','py-3',{active:isActive.my}]">{{$t('nav.my')}}</a>
            </li>
        </ul>
    </nav>
    <div id="isupload" class="toast hide position-fixed bottom-10 start-50 translate-middle-x bg-warning " role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="2000">
      <div class="toast-body">
        {{$t('OrderListTable.isupload')}}
      </div>
  </div>
    <audio id="tip_notify">
    <source src="../../assets/sound/tip.wav" type="audio/wav">
    </audio>
</template>

<script>
    import Socket from "@/js/socket.io.js";
    import congif from '@/js/config';

    export default {
        name: "Nav",
        inject: ['reload'],
        components: {},
        data() {
            return {
                isActive:{payfor:true,pay:false,my:false},
                orderfor_notify:false,
                order_notify:false,
                order_num:'!',
                orderfor_num:'!',
            }
        },
        methods: {
            doHideNotify(parm){
                this.isActive.payfor = this.isActive.pay = this.isActive.my =false;
                if(parm == 'order'){
                    this.$router.push({ path:'/OrderReserve'});
                    this.isActive.pay=true;
                    localStorage.setItem("order_notify",0);
                    this.order_notify = false;
                    // this.order_num = 5;
                }else if(parm == 'orderfor'){
                    this.$router.push({ path:'/OrderForReserve'});
                    this.isActive.payfor=true;
                    localStorage.setItem("orderfor_notify",0);
                    this.orderfor_notify = false;
                    // this.orderfor_num = 5;
                }else if(parm == 'my'){
                    this.$router.push({ path:'/UserInfo'});
                    this.isActive.my=true;
                }
                this.reload();
            },
            socketConn(){
                //Socket連線-------------------------------------------
                //可接收的adminid
                let assign = {
                    'user_id' : localStorage.getItem("user_id")
                };
                //監聽key
                
                let vSocket = this.socketInit(assign, this.fu_mSocket);
                //Socket連線-----------------------------------------end
            },
            fu_mSocket(mSocket){
                // let switch_order_notify =  this.order_notify;
                //完成Socket連線後做的事
                let monitorkey_order = 'pet_order';
                this.socketMonitor(mSocket, monitorkey_order, this.fn_data); 
            },
            fn_data(data){
                    //收到推播後做的事
                    if(data[0] == 'order'){
                        localStorage.setItem("order_notify",1);
                        // $('.order_notify').show();
                        this.order_notify = true;
                        // this.order_num = 5;
                        let tip_notify = document.getElementById("tip_notify");
                        const videoPromise = tip_notify.play();
                        if (videoPromise !== undefined) {
                            playPromise.then(() => {
                                tip_notify.pause();
                                // Automatic playback started!
                                // Show playing UI.
                                tip_notify.play();
                            })
                            .catch(error => {
                                // Auto-play was prevented
                                // Show paused UI.
                            });
                        }
                    }else if(data[0] == 'orderfor'){
                        localStorage.setItem("orderfor_notify",1);
                        // $('.orderfor_notify').show();
                        this.orderfor_notify = true;
                        // this.orderfor_num = 5;
                        let tip_notify = document.getElementById("tip_notify");
                        const videoPromise = tip_notify.play();
                        if (videoPromise !== undefined) {
                            playPromise.then(() => {
                                tip_notify.pause();
                                // Automatic playback started!
                                // Show playing UI.
                                tip_notify.play();
                            })
                            .catch(error => {
                                // Auto-play was prevented
                                // Show paused UI.
                            });
                        }
                    }
                },
            socketInit(assign = {}, func) {
                let uid = Math.floor(Math.random()*99)+""+Date.parse(new Date()); //產生uid
                // let connect_url = 'http://cf3ws.com:1125';
                // let connect_url = 'https://wsstest.ceterveryative.com';
                let connect_url = congif.socketurl();
                
                // let connect_url = Config.site.socket_url; 
                let iSocket = Socket.connect(connect_url);  //連線
                console.log("[socket init] "+Date()+" 開始連線至 "+connect_url);
            
                iSocket.uid = uid;
                iSocket.monitor = [];
                let loginData = {
                    uid: uid,
                    data: assign
                };
                iSocket.on('connect', function(){
                    console.log("[socket login] "+Date()+" 連線成功");
                    iSocket.emit('login', loginData);
                    console.log("[socket login] 登入ID:"+uid);
                    console.log("[socket login] 登入資料:");
                    console.log(loginData);
                    
                    if (typeof func === 'function') {
                        func(iSocket);
                    }
                });
            
                return iSocket;
            },
            socketMonitor(iSocket, key, func_onsocket, func_callback, log = true) {
                let iMonitor = iSocket.monitor;
                if(iMonitor.indexOf(key) != -1){
                    console.log("[socket monitor] "+iSocket.uid+" 監聽已存在 key:"+key);
                    return;
                }
                iMonitor.push(key);
                let data = {
                    uid: iSocket.uid,
                    monitor_key: key
                };
                console.log("[socket monitor] "+iSocket.uid+" 開啟監聽key:"+key);
                iSocket.on(key, function(data){
                    if(log){
                        console.log("[socket monitor] "+Date()+" "+iSocket.uid+" 收到推播 key:"+key);
                        console.log("[socket monitor] 推播資料:");
                        console.log(data);
                    }
                    
                    if (typeof func_onsocket === 'function') {
                        func_onsocket(data);
                    }
                });
                console.log("[socket monitor] "+iSocket.uid+" 登記監聽key:"+key);
                initdata(iSocket, key);

                function initdata(iSocket, key, loadtime = 0){
                    iSocket.emit('set_monitor', data);
                    // let options = {url: 'ajax/socketapi', data: {uid:iSocket.uid,key:key}, success:function(r){
                    //     if(r != 1 && loadtime <50){
                    //         console.log("[socket monitor] "+iSocket.uid+" 無回應, 重新登記"+key+" 第"+(loadtime+1)+"次");
                    //         loadtime++;
                    //         initdata(iSocket, key, loadtime);
                    //     }
                    // }};
                    // $.ajax(options);
                }
            }
        },
        created() {
            if(localStorage.getItem("order_notify") == 1){
                this.order_notify = true;
                // this.order_num = 5;
            }
            if(localStorage.getItem("orderfor_notify") == 1){
                this.orderfor_notify = true;
                // this.orderfor_num = 5;
            }
        },
        mounted(){
            this.socketConn();
        }
    }
    // socketConn();
</script>

<style scoped lang="scss">
    nav {
        background: rgb(231, 231, 231);
        box-shadow: 0px -3px 3px #00000046;
        background-size: cover;
        background-position: top center;
    }

    nav button {
        background: transparent;
        border: none;
        font-size: 20px;

    }
    $nav_active_color:#332c1c;
    $nav_color:#332c1c;
    .nav-link.active::before{
        content:'●';
        // color: #ffc234!important;
        // transform: translate(0%, -2%) scale(1.2);
    }
    .nav-link{
        color: #202020!important;
    }
    /* nav {
        background: url('~@/assets/images/tabbar_bg.png') no-repeat;
        background-size: cover;
        background-position: top center;
    }

    nav button {
        background: transparent;
        border: none;
        font-size: 20px;

    }
    $nav_active_color:#332c1c;
    $nav_color:#332c1c;
    .nav-link.active{
        color: #ffc234!important;
        // transform: rotate(0.05turn);
        transform: translate(0%, -2%) scale(1.2);
        text-shadow: 
        1px     0px     0px $nav_active_color,
        -1px    0px     0px $nav_active_color,
        0px     1px     0px $nav_active_color,
        0px     -1px    0px $nav_active_color,
        2px     0px     0px $nav_active_color,
        ;
    }
    .nav-link{
        color: #f4f5f6!important;
        text-shadow: 
        1px     0px     0px $nav_color,
        -1px    0px     0px $nav_color,
        0px     1px     0px $nav_color,
        0px     -1px    0px $nav_color,
        2px     0px     0px $nav_color,
        ;
    } */
    .nav-item{
        position: relative;
    }
    .o_notify{
        // display: none;
        position: absolute;
        width: 20px;
        height: 20px;
        background: #f00;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-weight: bold;
        vertical-align: middle;
        line-height: 20px;
        top: 8px;
        right: 0;
    }
    // .active.o_notify{
    //     display: block;
    // }
    
    .bottom-10{
    bottom: 10%;
    }
</style>