<template>
    AssetInfo
</template>

<script>
    export default {
        name: "AssetInfo",
        components: {},
        data() {
            return {}
        },
        methods: {},
        created() {
        }
    }
    ;
</script>

<style>
</style>
