<template>
    <div class="card-body">
        <table class="table table-borderless table-sm">
            <tr>
                <th class="w-50" scope="row">{{$t('TeamTable.username')}}：</th>
                <td class="w-50">{{item.username}}</td>
            </tr>
            <tr>
                <th class="w-50" scope="row">{{$t('TeamTable.mobile')}}：</th>
                <td class="w-50">{{item.mobile}}</td>
            </tr>
            <tr>
                <th class="w-50" scope="row">{{$t('TeamTable.status')}}：</th>
                <td class="w-50">{{changeStatus(item.status)}}</td>
            </tr>
            <tr>
                <th class="w-50" scope="row">{{$t('TeamTable.logintime')}}：</th>
                <td class="w-50">{{item.logintime}}</td>
            </tr>
            <tr>
                <th class="w-50" scope="row">{{$t('TeamTable.pid_name')}}：</th>
                <td class="w-50">{{item.pid_name}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        props: ["item"],
        methods: {
            changeStatus(status) {
                switch (status) {
                    case 'normal':
                        return this.$t('TeamTable.status_normal');
                    case 'hidden':
                        return this.$t('TeamTable.status_hidden');
                    default:
                        return status;
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>