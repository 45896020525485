export default {
    // 圖片阿里雲位置
    imgUrl: function () {
        // return 'https://bkttest72.oss-accelerate.aliyuncs.com/'; //H5的改为在ngixn配置，嵌入app里的需要打开此地址
        // return 'https://bktlt.oss-accelerate.aliyuncs.com/'; //H5的改为在ngixn配置，嵌入app里的需要打开此地址
        return '';
    },
    // API 位置
    domain: function () {
        // return 'http://172.28.128.3:9010/'; // local
        return 'https://ftapi.estest.xyz/'; // 測試機
        // return 'https://petapilf.vicarticleast.com/'; // LT
        // return 'https://petapiqt.vicarticleast.com/'; // QX
    },
    // socket
    socketurl: function () {
        return 'https://wsstest.ceterveryative.com'; // 測試機
        // return 'https://wsslt.ceterveryative.com'; // LT
        // return 'https://wssqx.ceterveryative.com'; // QX
    },

}