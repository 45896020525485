<template>
<div class="container">
    <div class="row">
    <div
            v-for="(item, index) in data_MoneyLog"
            :key="data_MoneyLog"
            class="col-md-6 col-12 card_list my-1"
    >
    <div class="card shadow">
        <!--        <div class="overflow-hidden" style="height: 12rem;"></div>-->
        <div class="card-body">
            <table class="table table-borderless table-sm mb-0">
                <tr>
                    <th scope="row">{{$t('MoneyLog.order_num')}}：</th>
                    <td>{{ item.order_num }}</td>
                </tr>
                <tr>
                    <th scope="row">{{$t('MoneyLog.createtime')}}：</th>
                    <td>{{ item.createtime }}</td>
                </tr>
                <tr>
                    <th scope="row">{{$t('MoneyLog.type')}}：</th>
                    <td>
                        <b>{{changeType(item.type)}}</b>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{$t('MoneyLog.before')}}：</th>
                    <td>{{ item.before }}</td>
                </tr>
                <tr>
                    <th scope="row">{{$t('MoneyLog.money')}}：</th>
                    <td>{{ item.money }}</td>
                </tr>
                <tr>
                    <th scope="row">{{$t('MoneyLog.after')}}：</th>
                    <td>{{ item.after }}</td>
                </tr>
            </table>

        </div>
        </div>
    </div>
    </div>
    </div>

</template>

<script>
    export default {
        props: ["data_MoneyLog"],
        methods: {
            changeType(type) {
                switch (type) {
                    case 'recharge':
                        // return "充值";
                        return this.$t('MoneyLog.recharge');
                    case  'payfor':
                        // return "代付";
                        return this.$t('MoneyLog.payfor');
                    case  'payforback':
                        // return "代付退回";
                        return this.$t('MoneyLog.payforback');
                    case  'payforfee':
                        // return "代付手续费";
                        return this.$t('MoneyLog.payforfee');
                    case  'payforcancelback':
                        // return "取消交易代付点数退回";
                        return this.$t('MoneyLog.payforcancelback');
                    case  'payforcancelfeeback':
                        // return "取消交易代付提成退回";
                        return this.$t('MoneyLog.payforcancelfeeback');
                    case  'payforfeeback':
                        // return "代付手续费退回";
                        return this.$t('MoneyLog.payforfeeback');
                    case  'sell':
                        // return "卖出";
                        return this.$t('MoneyLog.sell');
                    case  'sellback':
                        // return "卖出失败退回";
                        return this.$t('MoneyLog.sellback');
                    case  'withdraw':
                        // return "提现";
                        return this.$t('MoneyLog.withdraw');
                    case  'withdrawback':
                        // return "提现失败退回";
                        return this.$t('MoneyLog.withdrawback');
                    case  'withdrawfee':
                        // return "提现手续费";
                        return this.$t('MoneyLog.withdrawfee');
                    case  'withdrawfeeback':
                        // return "提现手续费退回";
                        return this.$t('MoneyLog.withdrawfeeback');
                    case  'in':
                        // return "转入";
                        return this.$t('MoneyLog.in');
                    case  'out':
                        // return "转出";
                        return this.$t('MoneyLog.out');
                    case  'hand':
                        // return "人工加减款";
                        return this.$t('MoneyLog.hand');
                    case  'profit':
                        // return "提成";
                        return this.$t('MoneyLog.profit');
                    case  'profitback':
                        // return "提成退回";
                        return this.$t('MoneyLog.profitback');
                    case  'xprofit':
                        // return "分享收益";
                        return this.$t('MoneyLog.xprofit');
                    case  'xprofitback':
                        // return "分享收益退回";
                        return this.$t('MoneyLog.xprofitback');
                    default:
                        return type;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .card_list:nth-child(odd) .card{
        background: #f0feff!important;
    }
    .card_list:nth-child(even) .card{
        background: #fffcf0!important;
    }
</style>