<template>
    <TemplateTeam :data_team="data_team"/>
</template>

<script>
    import TemplateTeam from '../Template/Team';
    import http from '../../js/axios.js';

    export default {
        name: "Team",
        components: {TemplateTeam},
        data() {
            return {
                data_team: "",
            };
        },
        methods: {},
        created() {
            let token = localStorage.getItem("token");

            //我的團隊
            http.get('api/pet/team', {token: token}, response => {
                let data = response.data;
                if (data.code === 1) {
                    console.log(data.data);
                    this.data_team = data.data;
                }
            });
        },
    }
</script>

<style>
</style>
