<template>
    <header class="m-auto fixed-top d-flex align-items-center">
        <router-link v-if="$route.path == '/OrderList' || $route.path == '/OrderForList' || $route.path == '/MoneyLog' || $route.path == '/Team' || $route.path == '/Set'" to="/UserInfo" class="nav-link text-dark fw-bold py-3 btn-back"><BIconReply /></router-link>
        <router-link v-if="$route.path == '/Lang'" to="/Set" class="nav-link text-dark fw-bold py-3 btn-back"><BIconReply /></router-link>
        <router-link v-if="$route.path == '/OrderForPayee'" to="/OrderForReserve" class="nav-link text-dark fw-bold py-3 btn-back"><BIconReply /></router-link>
        <div class="d-flex justify-content-center w-100">
            <div class="w-75">
                <h2 class="text-dark text-center fw-bold fs-4 m-0">{{title}}</h2>
            </div>
            <div class="w-25">
                <a class="text-dark text-decoration-none" href="javascript:;" @click='reload()'>{{$t('header.refresh')}}</a>
            </div>
        </div>
    </header>
</template>

<script>
    import router from '../../router'

    export default {
        name: "Home",
        props: ['page_name'],
        inject: ['reload'],
        data() {
            return {
            };
        },
        created() {
            this.changeTitle(this.page_name);
            router.afterEach((to, from, next) => {
                this.changeTitle(to.name);
            });
        },
        methods: {
            changeTitle(name) {
                switch (name) {
                    case 'OrderForReserve':
                        // this.title = '买入';
                        this.title = this.$t('header.payfor');
                        break;
                    case 'OrderReserve':
                        this.title = this.$t('header.pay');
                        break;
                    case 'OrderForList':
                        this.title = this.$t('header.payforRecord');
                        break;
                    case 'OrderList':
                        this.title = this.$t('header.payRecord');
                        break;
                    case 'OrderForPayee':
                        this.title = this.$t('header.payforPayee');
                        break;
                    case 'MoneyLog':
                        this.title = this.$t('header.assetDetails');
                        break;
                    case 'Team':
                        this.title = this.$t('header.myTeam');
                        break;
                    case 'Set':
                        this.title = this.$t('header.settings');
                        break;
                    default:
                        this.title = this.$t('header.my');
                        break;
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    header {
        background: rgb(231, 231, 231);
        box-shadow: 0px 3px 3px #00000046;
        background-size: cover;
        background-position: bottom center;
        min-height: 60px;
    }
    /* header {
        background: url('~@/assets/images/top_bg.png') no-repeat;
        background-size: cover;
        background-position: bottom center;
        min-height: 60px;
    } */
    .btn-back{ 
        line-height: 10px;
        position: absolute;
        top: 0;
        font-size: 26px;
    }
</style>