import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue'
// import { createI18n } from '@yangss/vue3-i18n'
import i18n from '@/js/i18n'
import Particles from "particles.vue3";
// createApp(App).use(router).mount('#app')



const myApp = createApp(App)
myApp.use(router)
myApp.use(BootstrapIconsPlugin)
myApp.use(i18n)
myApp.use(Particles)
myApp.mount('#app')