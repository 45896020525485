<template>
<div class="container">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item bg-light" role="presentation">
            <button class="nav-link active" active-class="active" id="home-tab" data-bs-toggle="tab" data-bs-target="#level1" type="button"
                    role="tab" aria-controls="home" aria-selected="true">{{$t('Team.memberLevel1')}}
            </button>
        </li>
        <li class="nav-item bg-light" role="presentation">
            <button class="nav-link" active-class="active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#level2" type="button"
                    role="tab" aria-controls="profile" aria-selected="false">{{$t('Team.memberLevel2')}}
            </button>
        </li>
        <li class="nav-item bg-light" role="presentation">
            <button class="nav-link" active-class="active" id="contact-tab" data-bs-toggle="tab" data-bs-target="#level3" type="button"
                    role="tab" aria-controls="contact" aria-selected="false">{{$t('Team.memberLevel3')}}
            </button>
        </li>
    </ul>

    <div class="tab-content " id="myTabContent">
        <div class="tab-pane fade show active " id="level1" role="tabpanel" aria-labelledby="home-tab">
            <div
                    v-for="(item, index) in data_team.level1"
                    :key="data_team.level1"
                    class="card shadow w-100"
            >
                <TeamTable :item=item></TeamTable>
            </div>
        </div>



        <div class="tab-pane fade" id="level2" role="tabpanel" aria-labelledby="profile-tab">
            <div
                    v-for="(item, index) in data_team.level2"
                    :key="data_team.level2"
                    class="card shadow col-12"
            >
                <TeamTable :item=item></TeamTable>
            </div>
        </div>
        <div class="tab-pane fade" id="level3" role="tabpanel" aria-labelledby="contact-tab">
            <div
                    v-for="(item, index) in data_team.level3"
                    :key="data_team.level3"
                    class="card shadow col-12"
            >
                <TeamTable :item=item></TeamTable>
            </div>
        </div>
    </div>



</div>
</template>
<script>
    import TeamTable from './TeamTable';
    export default {
        components: {TeamTable},
        props: ["data_team"]
    }
</script>

<style scoped lang="scss">
.nav-link{
    transform: translate(0%, 12%) rotate(0.01turn);
    background: #f8f9fa !important;
    color: #aaa!important;
}
$nav_active_color:#cc3b16;
    .nav-link.active{
        background: #fff!important;
        color: #333!important;
        font-weight: 700;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.555);
        transform: translate(0%, -6%) rotate(-0.01turn);
        // text-shadow: 
        // 1px     0px     2px $nav_active_color, 
        // -1px    0px     2px $nav_active_color, 
        // 2px     0px     2px $nav_active_color,
        // -2px    0px     2px $nav_active_color,
        // 3px     0px     3px $nav_active_color,
        // -3px    0px     3px $nav_active_color,
        // 0px     1px     1px $nav_active_color, 
        // 0px     -1px    1px $nav_active_color, 
        // 0px     2px     1px $nav_active_color,
        // 0px     -2px    1px $nav_active_color,
        // 0px     3px     1px $nav_active_color,
        ;
    }
</style>