<template>

    <div class="wrapper d-flex justify-content-center position-relative">
        <div class="cloud_01"></div>
        <div class="cloud_02"></div>
        <div class="d-flex align-self-center" style="max-width:420px;width:100%">
            <form id="Login_Form" class="w-100" @submit.prevent="vueSubmit">
                <div class="form-signin">
                    <h3 class="form-signin-heading login_title">登入</h3>
                    <hr class="colorgraph"/>
                    <p class="text-danger text-center" v-show="isShowLoginMsg">帳號或密碼錯誤</p>
                    <br>
                    <input type="text" class="form-control" name="Username" placeholder="帳號" v-model="account"
                           required="" autofocus=""/>
                    <input type="password" class="form-control" name="Password" placeholder="密碼" v-model="password"
                           required=""/>
                    <select class="form-select" name="lang" v-model="lang">
                        <option value="zh" selected>中文(Chinese)</option>
                        <option value="en">英文(English)</option>
                        <option value="ja">日本(Japanese)</option>
                    </select>
                    <button class="btn btn-lg btn-my w-100" type="submit" @click="setLocale(this.lang)">送出</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import http from '../js/axios.js';
    import router from '../router'

    export default {
        name: 'login',
        inject: ['setLocale'],
        data() {
            return {
                account: "",
                password: "",
                lang: 'zh',
                isShowLoginMsg: false,
            }
        },
        methods: {
            vueSubmit() {
                this.isShowLoginMsg = false;
                http.get('api/user/login', {account: this.account, password: this.password}, response => {
                    console.log(response);
                    if (response.data.code === 1) {
                        localStorage.setItem("user_id", response.data.data.userinfo.id); //紀錄id 用來判斷推播接收的身份
                        localStorage.setItem("token", response.data.data.userinfo.token); // 是否登入
                        // location.reload();

                        router.push({path: '/index',});
                    } else {
                        this.isShowLoginMsg = true;
                    }
                });
            }
        },
    }
</script>

<style lang="scss">
    .wrapper {
        background-position: center 0;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -ms-background-size: cover;
        height: 100vh;
        
    }

    /* .cloud_01 {
        background: url(~@/assets/images/cloud_01.png) no-repeat;
        background-size: contain;
        height: 200px;
        width: 600px;
        max-width: 100%;
        position: absolute;
        top: 5%;
        left: 5%;
    }

    .cloud_02 {
        background: url(~@/assets/images/cloud_02.png) no-repeat;
        background-size: contain;
        height: 200px;
        width: 600px;
        max-width: 100%;
        position: absolute;
        top: 5%;
        right: 0%;
    } */

    .form-signin {
        max-width: 420px;
        width: 100%;
        padding: 3rem;
        // border-radius: 30px;
        background: rgba(13, 41, 94, 0.9);
        // background: url(~@/assets/images/login_bg.svg);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
        background-size: cover;
        background-position: center;
    }

    .btn-my {
        background-color: #2e3e53 !important;
        color: #ffffff !important;
        border-bottom: 5px solid #252c3a !important;
    }

    .form-signin-heading {
        text-align: center;
        margin-bottom: 30px;
    }


    $inp_bgc: rgb(255, 255, 255);
    $inp_border: 1px solid rgb(206, 206, 206);
    input[type="text"] {
        margin-bottom: 0px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $inp_bgc;
        border: $inp_border;
    }

    input[type="password"] {
        /*margin-bottom: 20px;*/
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: $inp_bgc;
        border: $inp_border;
    }
    select[name="lang"]{
        margin-bottom: 20px;
    }

    .login_title {
        text-align: center;
        font-size: 26px;
        color: #ffc234;
        font-weight: 600;
        text-shadow: 1px 1px 3px #000;
    }

    @media screen and (max-width: 1200px) {

    }

    @media screen and (max-width: 992px) {

    }

    @media screen and (max-width: 768px) {
        .cloud_02 {
            right: 0%;
            left: 0%;
            margin: auto;
        }
        .cloud_01 {
            display: none;
        }
    }

    @media screen and (max-width: 576px) {
    }
</style>