<template>
    <!--        OrderForReserve-->
    <Article :cardsList="orderForReserveOrder" :sticky="orderForReserve" :type="'payfor'"/>
</template>

<script>
    import Article from "@/components/Template/Article";
    import http from "@/js/axios.js";

    export default {
        name: "OrderForReserve",
        components: {Article},
        data() {
            return {
                orderForReserve: "", // 買入預約 - 對應代付搶單
                orderForReserveOrder: "", //買入列表
            };
        },
        methods: {},
        created() {
            localStorage.setItem("orderfor_notify",0);
            // $('.orderfor_notify').hide();
            let token = localStorage.getItem("token");
            http.get("api/pet/orderForReserveOrder", {token: token}, (response) => {
                this.orderForReserveOrder = response.data;
                response.data.data.forEach(function(dataitem){
                    localStorage.setItem("orderForReserveOrder_"+dataitem.trade_no, JSON.stringify(dataitem));
                });
                // console.log(this.orderForReserveOrder);
            });
            http.get("api/pet/orderForReserve", {token: token}, (response) => {
                this.orderForReserve = response.data;
                // console.log(this.orderForReserve);
            });
        },
    };
</script>

<style></style>
