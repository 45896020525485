<template>
    <router-view v-if="isRouterAlive" :page_name="page_name"></router-view>
</template>

<script>
    import router from "./router";
    import congif from './js/config'
    import {useI18n} from "vue3-i18n";

    export default {
        setup() {
            const i18n = useI18n();
            const setLocale = (lang) => {
                console.log('setLocale');
                i18n.setLocale(lang);
                console.log(lang)
            };

            return {
                setLocale,
            };
        },
        provide() {
            return {
                reload: this.reload,
                setLocale: this.setLocale,
            };
        },
        data() {
            return {
                isRouterAlive: true,
                page_name: '',
            };
        },
        methods: {},
        created() {
            //切頁至頂
            router.afterEach((to,from,next) => {
            window.scrollTo(0,0);
            });

            localStorage.setItem("aliyuncs_url", congif.imgUrl());
            var style = localStorage.getItem("style");
            if (style) {
                $("#style").attr("href", style); //实现将主题保存在内存中刷新浏览器不改变
            }
            router.beforeEach((to, from, next) => {
                // localStorage.removeItem("token"); // 刪除登入
                let isLogin = localStorage.getItem("token"); // 是否登入
                this.page_name = to.name;
                // console.log('token = ' + isLogin);
                // console.log('page name = ' + to.name);
                // 當路由到登入頁時，已登入狀態、跳轉至index
                if (to.name === "login") {
                    // console.log('首頁');
                    if (isLogin != null) {
                        // console.log('已登入、轉至首頁');
                        router.push({path: "/index"});
                    }
                } else if (to.name === undefined) {
                    // console.log('未知頁面');
                    router.push({path: "/index"});
                }
                // 當路由到route其它頁時，未登入狀態、跳轉至login
                else {
                    // console.log('其它頁面');
                    if (isLogin === null) {
                        // console.log('未登入、轉至登入');
                        router.push({path: "/login"});
                    }
                }
                // console.log('next');
                next();
            });
        },
    };
</script>

<style lang="scss">
    @import "~bootstrap/scss/bootstrap";

</style>
