<template>
    <div class="overflow-hidden" style="height: 12rem;">
        <img :src="aliyuncs_url+item.pet.image" class="card-img-top" alt=""/>
    </div>
    <div class="card-body">
        <table class="table table-borderless table-sm">
            <tr>
                <th scope="row">{{$t('OrderListTable.DigitalCurrencyName')}}：</th>
                <td>{{ item.pet.name }}</td>
            </tr>
            <tr>
                <th scope="row">{{$t('OrderListTable.trade_no')}}：</th>
                <td>{{ item.trade_no }}</td>
            </tr>
            <tr>
                <th scope="row">{{$t('OrderListTable.amount')}}：</th>
                <td>{{ item.amount }}</td>
            </tr>
            <tr>
                <th scope="row">{{$t('OrderListTable.createtime')}}：</th>
                <td>{{ item.createtime }}</td>
            </tr>
            <tr>
                <th scope="row">{{$t('OrderListTable.user_in')}}：</th>
                <td>{{ item.user_in }}</td>
            </tr>
            <tr>
                <th scope="row">{{$t('OrderListTable.status')}}：</th>
                <td class="badge p-2" :class="status_bg">
                    {{ changeStatus(item.status) }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        props: ["item","type"],
        data() {
            return {
                aliyuncs_url : localStorage.getItem("aliyuncs_url"),
                stickyStatus: '',
                status_bg:'',
            }
        },
        methods: {
            changeStatus(status) {
                if(this.type == 'pay'){
                    switch (status) {
                        case -1:
                            this.status_bg = 'bg-danger'
                            return this.$t('OrderListTable.pay_statusNeg1');
                        case 0:
                            this.status_bg = 'bg-success'
                            return this.$t('OrderListTable.pay_status0');
                        case 1:
                             this.status_bg = 'bg-dark'
                            return this.$t('OrderListTable.pay_status1');
                        case 2:
                             this.status_bg = 'bg-danger'
                            return this.$t('OrderListTable.pay_statusNeg1');
                        default:
                            return status;
                    }
                }else{
                    switch (status) {
                        case -1:
                            this.status_bg = 'bg-danger'
                            return this.$t('OrderListTable.payfor_statusNeg1');
                        case 0:
                            this.status_bg = 'bg-success'
                            return this.$t('OrderListTable.payfor_status0');
                        case 1:
                            this.status_bg = 'bg-dark'
                            return this.$t('OrderListTable.payfor_status1');
                        case 2:
                            this.status_bg = 'bg-danger'
                            return this.$t('OrderListTable.payfor_statusNeg1');
                        default:
                            return status;
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>