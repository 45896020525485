<template>
    <Usercenter :data_info="data_info" :data_assetInfo="data_assetInfo"/>
</template>

<script>
    import Usercenter from '../Template/Usercenter';
    import http from '../../js/axios.js';

    export default {
        name: "UserInfo",
        components: {Usercenter},
        data() {
            return {
                data_info: "",
                data_assetInfo: "",
            };
        },
        props:['page_name'],
        methods: {},
        created() {
            let token = localStorage.getItem("token");

            //個人信息
            http.get('api/user/info', {token: token}, response => {
                let data = response.data;
                if (data.code === 1) {
                    this.data_info = data.data;
                }
            });

            //資產信息
            http.get('api/pet/assetInfo', {token: token}, response => {
                let data = response.data;
                if (data.code === 1) {
                    this.data_assetInfo = data.data;
                }
            });
        },
    }
</script>

<style scoped lang="scss">
    
</style>
