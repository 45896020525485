<template>
  <div class="container">
    <h2 class="title_css d-block p-3">{{$t('orderpayee.orderpayeeinfo')}}</h2>
    <div class="appt-info shadow my-2">
      <ul class="list-group list-group-flush">
        <li v-if="script_active == 1" class="list-group-item list-group-item-action text-danger">
          <b>警示：该订单已执行过，请确认是否已出帐，避免二次执行。</b>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_Payee">{{ $t('orderpayee.bank_account')}}：</label> <input type="text" :value="payee_account.bank_account" id="copy_Payee" disabled >
          <button @click="copy('copy_Payee')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_account">{{ $t('orderpayee.card_no')}}：</label> <input type="text" :value="payee_account.card_no" id="copy_account" disabled >
          <button @click="copy('copy_account')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_bank_name">{{ $t('orderpayee.bank_name')}}：</label> <input type="text" :value="payee_account.bank_name" id="copy_bank_name" disabled >
          <button @click="copy('copy_bank_name')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_sub_bank">{{ $t('orderpayee.sub_bank')}}：</label> <input type="text" :value="payee_account.sub_bank" id="copy_sub_bank" disabled >
          <button @click="copy('copy_sub_bank')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_money">{{ $t('orderpayee.defaultmoney')}}：</label> <input type="text" :value="defaultmoney" id="copy_money" disabled >
          <button @click="copy('copy_money')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
      </ul>
      <input type="text" id="copy_text" readonly="true" value="">
    </div>
    <div id="iscopy" class="toast hide position-fixed bottom-10 start-50 translate-middle-x bg-warning " role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="2000">
      <div class="toast-body">
        {{$t('orderpayee.iscopy')}}
      </div>
  </div>
  </div>
</template>

<script>
import Lang from '../My/Lang.vue';
import { Toast } from 'bootstrap'

export default {
  components: {Lang},
  props: ["script_active","payee_account","defaultmoney"],
  created() {
          // this.$root.$bvToast.toast('1');
  },
  methods: {
      copy(copy_txt){
          var input = document.getElementById("copy_text");
          var input_copy_txt = document.getElementById(copy_txt);
          input.value = input_copy_txt.value;
          input.select();
          document.execCommand('Copy');
          let t = new Toast(document.getElementById("iscopy"));
          t.show()
      }
  }
};
</script>

<style scoped lang="scss">
#copy_text{
  position: absolute;
  left: -9999px;
}
.btn{
    vertical-align: top;
    }
input[type="text"]{
    height: 31px;
    padding: 0 10px;
}
.list-group-item label{
  width: 90px;
}
.bottom-10{
  bottom: 10%;
}
@media screen and (max-width: 480px) {
  .list-group-item label{
    width: 100%;
    margin-bottom: 3px;
  }
  .list-group-item input[type="text"]{
    width: 80%;
  }
  .list-group-item button{
    width: 20%;
  }
}
</style>
