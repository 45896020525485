<template>
    <TemplateMoneyLog :data_MoneyLog="data_MoneyLog" />
</template>

<script>
    import TemplateMoneyLog from '../Template/MoneyLog';
    import http from '../../js/axios.js';
    export default {
        name: "MoneyLog",
        components: {TemplateMoneyLog},
        data() {
            return {
                data_MoneyLog: "",
            };
        },
        methods: {},
        created() {
            let token = localStorage.getItem("token");

            //買入紀錄
            http.get('api/pet/MoneyLog', {token: token,limit:10}, response => {
                    console.log(response);
                let data = response.data;
                if (data.code === 1) {
                    // console.log(data.data);
                    this.data_MoneyLog = data.data;
                }
            });
        }
    }
</script>

<style>
</style>
