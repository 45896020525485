<template>
    <TemplateOrderList :payee_account="payee_account" :script_active="script_active" :defaultmoney="defaultmoney" />
</template>

<script>
    import TemplateOrderList from '../Template/OrderPayee';
    import http from "@/js/axios.js";
    export default {
        name: "OrderForPayee",
        components: {TemplateOrderList},
        data() {
            return {
                script_active: "",
                payee_account: "",
                defaultmoney: "",
            };
        },
        methods: {},
        created() {
            let token = localStorage.getItem("token");
            let orderForReserveOrder_item = JSON.parse(localStorage.getItem("orderForReserveOrder_"+this.$route.query.trade_no));
            console.log(orderForReserveOrder_item);
            this.defaultmoney = orderForReserveOrder_item.amount;
            this.script_active = orderForReserveOrder_item.script_active;
            this.payee_account = JSON.parse(orderForReserveOrder_item.payee_account);
            
            http.get("api/common/setScriptActive", {token: token, trade_no:this.$route.query.trade_no}, (response) => {
              console.log(response);
            });
        }
    }
    ;
</script>

<style>
</style>

