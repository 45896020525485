<template>
  <div class="container">
    <h2 class="title_css d-block p-3">{{$t('usercenter.myInformation')}}</h2>
    <div class="appt-info shadow my-2">
      <ul class="list-group list-group-flush">
        
        <li class="list-group-item list-group-item-action">
          {{ $t('usercenter.account')}}： {{ data_info.username }}
        </li>
        <li class="list-group-item list-group-item-action">
          {{ $t('usercenter.phone')}}： {{ data_info.mobile }}
        </li>
        <li class="list-group-item list-group-item-action">
          {{ $t('usercenter.balance')}}： {{ data_assetInfo.money }}
        </li>
        <li class="list-group-item list-group-item-action">
          {{ $t('usercenter.credits')}}： {{ data_assetInfo.credit }}
        </li>
        <li class="list-group-item list-group-item-action">
          {{ $t('usercenter.available_credit')}}： {{ parseFloat(data_assetInfo.money) + parseFloat(data_assetInfo.credit) }}
        </li>
        <li class="list-group-item list-group-item-action">
          {{ $t('usercenter.TodayIncome')}}： {{ data_assetInfo.earningsDay }}
        </li>
        <li class="list-group-item list-group-item-action">
          {{ $t('usercenter.MonthlyIncome')}}： {{ data_assetInfo.earningsMonth }}
        </li>
      </ul>
    </div>
  </div>
  <div id="pets" class="container">
    <div class="d-flex flex-wrap justify-content-center text-center p-3 shadow rounded-3 mg-opacity09">
            <router-link to="/OrderForList" class="link-secondary text-decoration-none fw-bold m-3">
              <div class="btn btn-warning rounded-3 fs-1 text-white shadow-sm">
                <BIconCartPlusFill />
              </div>
              <div>{{$t('usercenter.payforRecord')}}</div>
            </router-link>
            <router-link to="/OrderList" class="link-secondary text-decoration-none fw-bold m-3">
              <div class="btn btn-danger rounded-3 fs-1 text-white shadow-sm">
                <BIconCartDashFill />
              </div>
              <div>{{$t('usercenter.payRecord')}}</div>
            </router-link>
            <router-link to="/MoneyLog"  class="link-secondary text-decoration-none fw-bold m-3">
              <div class="btn btn-info rounded-3 fs-1 text-white shadow-sm">
                <BIconFileTextFill />
              </div>
              <div>{{$t('usercenter.assetDetails')}}</div>
            </router-link>
            <router-link to="/Team" class="link-secondary text-decoration-none fw-bold m-3">
              <div class="btn btn-success rounded-3 fs-1 text-white shadow-sm">
                <BIconFlagFill />
              </div>
              <div>{{$t('usercenter.myTeam')}}</div>
            </router-link>
            <router-link to="/Set" class="link-secondary text-decoration-none fw-bold m-3">
              <div class="btn btn-dark rounded-3 fs-1 text-white shadow-sm">
              <BIconGearFill />
              </div>
              <div>{{$t('usercenter.settings')}}</div>
            </router-link>

    </div>
  </div>
</template>

<script>

export default {
  components: {
   
  },
  props: ["data_info", "data_assetInfo",'GlobalData'],

};
</script>

<style scoped lang="scss">
.mg-opacity09 {
    background: rgba(255, 255, 255, 0.9);
}

</style>
