<template>
    <div class="container">
        <div class="d-flex justify-content-center text-center" style="height:calc(100vh - 116px)">
            <div class="d-flex align-items-center">
            <div class="d-flex p-3 shadow rounded-3 mg-opacity09">
                <router-link to="/Lang" class="link-secondary text-decoration-none mx-3 fw-bold">
                <div class="btn btn-danger rounded-3 fs-1 text-white shadow-sm">
                    <BIconType />
                </div>
                <div>{{$t('set.lang')}}</div>
                </router-link>

                <a class="link-secondary text-decoration-none fw-bold mx-3 m-auto d-block" href="javascript:;" @click='logout()'>
                <div class="btn btn-warning rounded-3 fs-1 text-white shadow-sm">
                        <BIconBoxArrowInRight />
                    </div>
                    <div>{{$t('set.logout')}}</div>
                </a>
            </div>
            </div>
        </div>


    </div>
</template>

<script>
    import http from "../../js/axios";
    import router from '../../router'

    export default {
        name: "Set",
        components: {},
        data() {
            return {}
        },
        methods: {
            logout() {
                let token = localStorage.getItem("token");
                http.get('api/user/logout', {token: token}, response => {
                    if (response.data.code === 1) {
                        localStorage.removeItem("token");
                        router.push({path: '/login',});
                    }
                });
            }
        },
        created() {
        }
    }
    ;
</script>

<style scoped lang="scss">
.mg-opacity09 {
    background: rgba(255, 255, 255, 0.9);
}

</style>
