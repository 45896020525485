import axios from 'axios'
import router from '../router'
import congif from './config'

let domain = congif.domain(); // 本機
let http = axios.create({
    baseURL: domain,
    withCredentials: false,
    transformRequest: [function (data) {
        let newData = '';
        for (let k in data) {
            if (data.hasOwnProperty(k) === true) {
                newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&';
            }
        }
        return newData;
    }]
});
let base_headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    //以下跨域内容改在nginx中配置
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': '*',
    // 'Access-Control-Allow-Headers': '*',
};

function apiAxios(method, url, params, response, headers) {
    http({
        method: method,
        url: domain + url,
        data: method === 'POST' || method === 'PUT' ? params : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        headers: (headers) ? headers : base_headers,
    }).then(function (res) {
        response(res);
    }).catch(function (err) {
        localStorage.removeItem("token");
        router.push({path: '/login',});
        // response(err);
    })
}

export default {
    get: function (url, params, response, headers = null) {
        return apiAxios('GET', url, params, response, headers)
    },
    post: function (url, params, response, headers = null) {
        return apiAxios('POST', url, params, response, headers)
    },
    put: function (url, params, response, headers = null) {
        return apiAxios('PUT', url, params, response, headers)
    },
    delete: function (url, params, response, headers = null) {
        return apiAxios('DELETE', url, params, response, headers)
    },
    upload: function (url, param, response) {
        let config = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        axios.post(domain + url, param, config)
            .then(function (res) {
                response(res);
            })
            .catch(function (err) {
                localStorage.removeItem("token");
                router.push({path: '/login',});
            });
    }
}