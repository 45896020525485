<template>
  <div class="container">
    <div class="d-flex flex-wrap">
      <div
        v-for="(item, index) in data_orderForList"
        :key="data_orderForList"
        class="col-md-6 col-12"
      >
        <div class="card shadow p-1 m-1">
          <OrderListTable :item="item" :type="'payfor'"></OrderListTable>
        </div>
      </div>

      <div
        v-for="(item, index) in data_orderList"
        :key="data_orderList"
        class="col-md-6 col-12"
      >
        <div class="card shadow p-1 m-1">
          <OrderListTable :item="item" :type="'pay'"></OrderListTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderListTable from "./OrderListTable";

export default {
  components: {
    OrderListTable,
  },
  props: ["data_orderForList", "data_orderList"],
};
</script>

<style scoped lang="scss">
  .card {
    background: rgba(255, 255, 255, 0.9);
  }
</style>
