<template>
    <Article :cardsList="orderReserveOrder" :sticky="orderReserve" :type="'pay'"/>
</template>

<script>
    import Article from "@/components/Template/Article";
    import http from "@/js/axios.js";

    export default {
        name: "OrderForReserve",
        components: {Article},
        data() {
            return {
                orderReserveOrder: "",
                orderReserve: "",
            };
        },
        methods: {},
        created() {
            localStorage.setItem("order_notify",0);
            // $('.order_notify').hide();
            let token = localStorage.getItem("token");
            http.get(
                "api/pet/orderReserveOrder",
                {token: token},
                (response) => {
                    this.orderReserveOrder = response.data;
                    // console.log(this.orderReserveOrder);
                }
            );
            http.get(
                "api/pet/orderReserve",
                {token: token},
                (response) => {
                    this.orderReserve = response.data;
                    // console.log(this.orderReserve);
                }
            );
        },
    };
</script>

<style></style>
